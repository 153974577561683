var prev =
	'<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="white"/><path d="M36.3536 24.6464C36.5488 24.8417 36.5488 25.1583 36.3536 25.3536L33.1716 28.5355C32.9763 28.7308 32.6597 28.7308 32.4645 28.5355C32.2692 28.3403 32.2692 28.0237 32.4645 27.8284L35.2929 25L32.4645 22.1716C32.2692 21.9763 32.2692 21.6597 32.4645 21.4645C32.6597 21.2692 32.9763 21.2692 33.1716 21.4645L36.3536 24.6464ZM14 24.5L36 24.5L36 25.5L14 25.5L14 24.5Z" fill="#98474F" style="transform: rotateZ(180deg);transform-origin: center;"/></svg>'
var next =
	'<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="white"/><path d="M36.3536 24.6464C36.5488 24.8417 36.5488 25.1583 36.3536 25.3536L33.1716 28.5355C32.9763 28.7308 32.6597 28.7308 32.4645 28.5355C32.2692 28.3403 32.2692 28.0237 32.4645 27.8284L35.2929 25L32.4645 22.1716C32.2692 21.9763 32.2692 21.6597 32.4645 21.4645C32.6597 21.2692 32.9763 21.2692 33.1716 21.4645L36.3536 24.6464ZM14 24.5L36 24.5L36 25.5L14 25.5L14 24.5Z" fill="#98474F"/></svg>'
var cardGallery = $('.owl-carousel-card')

$(function() {
	cardGallery = $('.owl-carousel-card')

	cardGallery.owlCarousel({
		loop: false,
		dots: true,
		nav: true,
		items: 1,
		slideBy: 1,
		navText: [prev, next], // Lembre-se de colocar 'prev' e 'next' entre aspas
		onInitialized: change, // Use onInitialized em vez de onInitialize
		onChanged: change, // Use onChanged em vez de onChange
		responsive: {
			0: {
				items: 1,
				autoWidth: false,
				dots: true,
				nav: true
			},
			1024: {
				items: 4,
				autoWidth: true,
				dots: false,
				nav: false
			}
		}
	})

	$('.navTimeline .owl-prev').on('click', function(e) {
		e.preventDefault()
		cardGallery.trigger('prev.owl.carousel')
	})

	$('.navTimeline .owl-next').on('click', function(e) {
		e.preventDefault()
		cardGallery.trigger('next.owl.carousel')
	})

	var alertDiv = $('#cards .alert')
	var cardTimeline = $('.card-timeline')

	// Mostra a div de alerta quando o mouse entra na div owl-carousel-card
	cardTimeline.on('mouseenter', function() {
		if ($(window).width() >= 1024) alertDiv.show()
	})

	// Esconde a div de alerta quando o mouse sai da div owl-carousel-card
	cardTimeline.on('mouseleave', function() {
		alertDiv.hide()
	})

	// Move a div de alerta para acompanhar o mouse dentro da div owl-carousel-card
	cardTimeline.on('mousemove', function(event) {
		alertDiv.css({
			left: event.clientX + 10,
			top: event.pageY - window.pageYOffset + 10 // Ajuste para levar em consideração o scroll da janela
		})
	})
})

function change(event) {
	cardGallery.css({ height: 'auto', opacity: 1 })
	// Adiciona a classe disabled ao botão prev quando o slide atual é o primeiro
	if (event.item.index === 0) {
		$('.navTimeline .owl-prev').addClass('disabled')
	} else {
		$('.navTimeline .owl-prev').removeClass('disabled')
	}

	// Obtém a largura do contêiner do carousel
	var containerWidth = cardGallery.width()

	// Calcula o número de itens visíveis com base na largura do contêiner e na largura dos itens
	var itemWidth = cardGallery
		.find('.owl-item')
		.eq(0)
		.width()
	var visibleItems = Math.floor(containerWidth / itemWidth)

	// Calcula o índice do último slide visível com base no índice do primeiro slide visível e no número de itens visíveis
	var firstVisibleIndex = event.item.index
	var lastIndexVisible = firstVisibleIndex + visibleItems - 1

	// Adiciona a classe disabled ao botão next quando o slide atual é o último
	if (lastIndexVisible >= event.item.count - 1) {
		$('.navTimeline .owl-next').addClass('disabled')
	} else {
		$('.navTimeline .owl-next').removeClass('disabled')
	}
}

// var rtime
// var timeout = false
// var delta = 300
// $(window).on('resize', function() {
// 	rtime = new Date()
// 	if (timeout === false) {
// 		timeout = true
// 		setTimeout(resizeend, delta)
// 	}
// })
// function resizeend() {
// 	if (new Date() - rtime < delta) {
// 		setTimeout(resizeend, delta)
// 	} else {
// 		timeout = false
// 		setTimeout(function() {
//             if (cardGallery.length) {
//                 cardGallery.trigger('initialize.owl.carousel')
//                 console.log("opa")
//             }
//         }, 1000)
// 	}
// }
