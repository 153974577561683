var heroGallery = $('.owl-carousel-hero')
var videoHero1 = $('#videoHero1')[0]
var videoHero2 = $('#videoHero2')[0]
var videoHero3 = $('#videoHero3')[0]
var ua = detect.parse(navigator.userAgent)

$(window).on('load', function() {
	if (mobileAndTabletCheck() && $(window).width() >= 1024) {
		$('#hero').addClass('mobile')
	}

	heroGallery = $('.owl-carousel-hero')
	heroGallery.owlCarousel({
		animateOut: 'fadeOut',
		loop: true,
		dots: true,
		nav: false,
		items: 1,
		slideBy: 1,
		dotsContainer: '#custom-owl-dots',
		autoplay: true,
		autoplayTimeout: 15000,
		onChange: changeVideo
	})

	// $('#hero').on('mouseenter', 'a', function(e) {
	// 	e.preventDefault()
	// 	// var videoFile = $(this).attr('href')
	// 	// var video = $('#videoHero')[0]
	// 	// video.src = videoFile
	// 	// video.load()
	// 	// video.play()
	// 	heroGallery.trigger('to.owl.carousel', [$(this).index(), 300]);
	// })
	$('.owl-dot').mouseenter(function(e) {
		e.preventDefault()
		heroGallery.trigger('to.owl.carousel', [$(this).index(), 300])
		var num = $(this).index() + 1
		if (num == 1) {
			videoHero1.play()
			// videoHero2.pause()
			// videoHero3.pause()
		} else if (num == 2) {
			//videoHero1.pause()
			videoHero2.play()
			//videoHero3.pause()
		} else if (num == 3) {
			// videoHero1.pause()
			// videoHero2.pause()
			videoHero3.play()
		}
	})
})
function changeVideo(event) {
	var pageIndex = event.page.index == -1 ? 0 : event.page.index
	var num = pageIndex + 1
	if (num == 1) {
		videoHero1.play()
	} else if (num == 2) {
		videoHero2.play()
	} else if (num == 3) {
		videoHero3.play()
	}
}

function mobileAndTabletCheck() {
	if (ua.device.type == 'Tablet' || ua.device.type == 'Mobile') {
		return true
	} else {
		return false
	}
}
