// Dependencies
import 'owl.carousel'
import './parts/detect'

// Parts
// import './parts/carousel-default'
import './parts/menu'
import './parts/hero'
import './parts/cards'
import './parts/videocampanha'
import './parts/lightboxs'
import './parts/regiao'
import './parts/load-page'
import './parts/scroll'
