import { Fancybox } from '@fancyapps/ui'

$(function() {
	Fancybox.bind('.btlightbox-campanha', {
		mainClass: 'c-lightbox c-lightbox__videocampanha',
		dragToClose: false
		// Html: {
		// 	videoTpl: `<video class="fancybox__html5video" loop playsinline controls controlsList="nodownload" poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn't support embedded videos.</video>`,
		// 	autoSize: false
		// }
	})
	Fancybox.bind('.link-navi-campanha', {
		mainClass: 'c-lightbox c-lightbox__videocampanha',
		dragToClose: false
		// Html: {
		// 	videoTpl: `<video class="fancybox__html5video" loop playsinline controls controlsList="nodownload" poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn't support embedded videos.</video>`,
		// 	autoSize: false
		// }
	})

	// Fancybox.bind('.btlightbox-making', {
	// 	mainClass: 'c-lightbox c-lightbox__making',
	// 	dragToClose: false,
	// 	Html: {
	// 		videoTpl: `<video class="fancybox__html5video" loop playsinline controls controlsList="nodownload" poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn't support embedded videos.</video>`,
	// 		autoSize: false
	// 	}
	// })

	Fancybox.bind('.link-regiao', {
		mainClass: 'c-lightbox c-lightbox__regiao',
		dragToClose: false,
		Html: {
			autoSize: false
		}
	})
})
