import * as scrollSite from './scroll'

$(function() {
	$('.bt_menu')
		.on('click', function(e) {
			e.preventDefault()
			$('.bt_menu .linha-2 .cor-2').stop()
			$('.bt_menu .linha-2 .cor-1').stop()
			$('.bt_menu .linha-1 .cor-2').stop()
			$('.bt_menu .linha-1 .cor-1').stop()
			$('.c-header').removeClass('fixedMenu')
			$('.c-header').removeClass('showMenu')
			if ($('body').hasClass('menuOpen')) {
				$('.c-header__logo').fadeIn('fast')
				if ($(window).scrollTop() > 70 || scrollSite.scrollTemp > 70) {
					$('.c-header').addClass('fixedMenu')
					$('.c-header').addClass('showMenu')
				}
			} else {
				$('.c-header__logo').fadeOut('fast')
			}
			$('body').toggleClass('menuOpen')
			$(this).toggleClass('openMenu')
		})
		.on('mouseenter', function() {
			$('.bt_menu .linha-2 .cor-2').stop()
			$('.bt_menu .linha-2 .cor-1').stop()
			$('.bt_menu .linha-1 .cor-2').stop()
			$('.bt_menu .linha-1 .cor-1').stop()
			if (!$(this).hasClass('openMenu')) {
				$('.bt_menu .linha-1 .cor-1').animate({ width: '0%' }, 500)
				setTimeout(function() {
					$('.bt_menu .linha-1 .cor-2').animate({ width: '100%' }, 500)
				}, 166)
				setTimeout(function() {
					$('.bt_menu .linha-2 .cor-1').animate({ width: '0%' }, 500)
				}, 333)
				setTimeout(function() {
					$('.bt_menu .linha-2 .cor-2').animate({ width: '100%' }, 500)
				}, 500)
			} else {
				$('.bt_menu .linha-2 .cor-2').css({ width: '0%' })
				$('.bt_menu .linha-2 .cor-1').css({ width: '100%' })
				$('.bt_menu .linha-1 .cor-2').css({ width: '0%' })
				$('.bt_menu .linha-1 .cor-1').css({ width: '100%' })
			}
		})
		.on('mouseleave', function() {
			$('.bt_menu .linha-2 .cor-2').stop()
			$('.bt_menu .linha-2 .cor-1').stop()
			$('.bt_menu .linha-1 .cor-2').stop()
			$('.bt_menu .linha-1 .cor-1').stop()
			if (!$(this).hasClass('openMenu')) {
				$('.bt_menu .linha-2 .cor-2').animate({ width: '0%' }, 500)
				setTimeout(function() {
					$('.bt_menu .linha-2 .cor-1').animate({ width: '100%' }, 500)
				}, 166)
				setTimeout(function() {
					$('.bt_menu .linha-1 .cor-2').animate({ width: '0%' }, 500)
				}, 333)
				setTimeout(function() {
					$('.bt_menu .linha-1 .cor-1').animate({ width: '100%' }, 500)
				}, 500)
			} else {
				$('.bt_menu .linha-2 .cor-2').css({ width: '0%' })
				$('.bt_menu .linha-2 .cor-1').css({ width: '100%' })
				$('.bt_menu .linha-1 .cor-2').css({ width: '0%' })
				$('.bt_menu .linha-1 .cor-1').css({ width: '100%' })
			}
		})
})

var rtime
var timeout = false
var delta = 300
$(window).on('resize', function() {
	rtime = new Date()
	if (timeout === false) {
		timeout = true
		setTimeout(resizeend, delta)
	}
})
function resizeend() {
	if (new Date() - rtime < delta) {
		setTimeout(resizeend, delta)
	} else {
		timeout = false
		if ($(window).width() >= 1024) {
			if ($('body').hasClass('menuOpen')) {
				$('.c-header__logo').fadeIn('fast')
				$('body').removeClass('menuOpen')
				$('.bt_menu').removeClass('openMenu')
			}
		} else {
		}
	}
}
