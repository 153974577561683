var prev =
	'<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="white"/><path d="M36.3536 24.6464C36.5488 24.8417 36.5488 25.1583 36.3536 25.3536L33.1716 28.5355C32.9763 28.7308 32.6597 28.7308 32.4645 28.5355C32.2692 28.3403 32.2692 28.0237 32.4645 27.8284L35.2929 25L32.4645 22.1716C32.2692 21.9763 32.2692 21.6597 32.4645 21.4645C32.6597 21.2692 32.9763 21.2692 33.1716 21.4645L36.3536 24.6464ZM14 24.5L36 24.5L36 25.5L14 25.5L14 24.5Z" fill="#98474F" style="transform: rotateZ(180deg);transform-origin: center;"/></svg>'
var next =
	'<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><circle cx="25" cy="25" r="25" transform="matrix(-1 0 0 1 50 0)" fill="white"/><path d="M36.3536 24.6464C36.5488 24.8417 36.5488 25.1583 36.3536 25.3536L33.1716 28.5355C32.9763 28.7308 32.6597 28.7308 32.4645 28.5355C32.2692 28.3403 32.2692 28.0237 32.4645 27.8284L35.2929 25L32.4645 22.1716C32.2692 21.9763 32.2692 21.6597 32.4645 21.4645C32.6597 21.2692 32.9763 21.2692 33.1716 21.4645L36.3536 24.6464ZM14 24.5L36 24.5L36 25.5L14 25.5L14 24.5Z" fill="#98474F"/></svg>'
var cardGallery = $('.owl-carousel-regiao')

$(function() {
	regiaoGallery = $('.owl-carousel-regiao')

	regiaoGallery.owlCarousel({
		loop: false,
		dots: true,
		nav: true,
		items: 1,
		slideBy: 1,
		navText: [prev, next]
	})
})
