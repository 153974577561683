import LocomotiveScroll from 'locomotive-scroll'

export var scrollSite
var elemScroll = document.querySelector('[data-scroll-container]')
export var scrollTemp = 0

$(function() {
	elemScroll = document.querySelector('[data-scroll-container]')
	scrollSite = new LocomotiveScroll({
		el: elemScroll,
		smooth: true,
		reloadOnContextChange: true
	})

	if ($(window).width() >= 1024) {
		scrollSite.on('scroll', args => {
			//console.log(scrollTemp , args.scroll.y)
			if (scrollTemp > args.delta.y) {
				$('.c-header').addClass('showMenu')
				if (args.delta.y < 70) {
					$('.c-header').removeClass('fixedMenu')
					$('.c-header').removeClass('showMenu')
				}else{
					$('.c-header').addClass('showMenu')
				}
			} else {
				if (args.delta.y > 70) {
					$('.c-header').addClass('fixedMenu')
					setTimeout(function(){$('.c-header').removeClass('showMenu')},100)
				}
			}
			scrollTemp = args.scroll.y
		})
	} else {
		$(window).on('scroll', function() {
			if (scrollTemp > $(window).scrollTop()) {
				$('.c-header').addClass('showMenu')
				if ($(window).scrollTop() < 70) {
					$('.c-header').removeClass('fixedMenu')
					$('.c-header').removeClass('showMenu')
				}else{
					$('.c-header').addClass('showMenu')
				}
			} else {
				if ($(window).scrollTop() > 70) {
					$('.c-header').addClass('fixedMenu')
					$('.c-header').removeClass('showMenu')
				}
			}
			scrollTemp = $(window).scrollTop()
		})
	}

	const sectionContent = document.getElementById('timeline')

	scrollSite.on('scroll', scroll => {
		// Obtenha a posição vertical atual da seção
		const sectionTop = sectionContent.getBoundingClientRect().top

		// Calcule a porcentagem de quanto a seção foi rolada na tela
		let percentageScrolled = Math.min(Math.max(0, 1 - sectionTop / window.innerHeight), 1)

		// Ajuste para começar a animação mais cedo
		percentageScrolled = Math.min(percentageScrolled + 0.2, 1)

		// Ajuste a escala do conteúdo da seção com base na porcentagem rolada
		const scale = 1.2 - 0.2 * percentageScrolled // Escala varia de 1.2 a 1

		// Ajuste a posição da seção para subir suavemente conforme o scroll
		const translateY = 100 * (1 - percentageScrolled) // Valor de 100% a 0%

		// Combine ambas as transformações em uma única transformação CSS com transições suaves
		sectionContent.style.transform = `scale(${scale}) translateY(${translateY}px)`
	})

	const sectionContent2 = document.getElementById('videocampanha_content')

	// Registre um ouvinte para o evento scroll do Locomotive Scroll
	scrollSite.on('scroll', scroll => {
		// Obtenha a posição vertical atual da seção
		const sectionTop = sectionContent2.getBoundingClientRect().top

		// Calcule a porcentagem de quanto a seção foi rolada na tela
		const percentageScrolled = Math.min(Math.max(0, 1 - sectionTop / window.innerHeight), 1)

		// Ajuste a escala do conteúdo da seção com base na porcentagem rolada
		const scale = 0.8 + 0.2 * percentageScrolled // Escala varia de 1 a 0.8

		// Aplique a transformação de escala ao conteúdo da seção
		sectionContent2.style.transform = `translate(-50%, -50%) scale(${scale})`
	})

	$('.linkAnchor').on('click', function(e) {
		e.preventDefault()
		var link = $(this).attr('href')
		const target = document.querySelector(link)
		$('body').removeClass('menuOpen')
		$('.bt_menu').removeClass('openMenu')
		if ($('body').hasClass('menuOpen')) $('.c-header__logo').fadeIn('fast')
		$('.c-header').addClass('fixedMenu')
		$('.c-header__logo').fadeIn('fast')

		scrollSite.scrollTo(target)
	})

	$('#hero .owl-dot').on('click', '.link-navi', function(e) {
		e.preventDefault()
		var link = $(this).attr('href')
		const target = document.querySelector(link)
		scrollSite.scrollTo(target)
	})

	$('#hero .owl-dot').on('click', '.link-navi-campanha', function(e) {
		var link = $(this).attr('data-link')
		const target = document.querySelector(link)
		scrollSite.scrollTo(target)
	})
})
$(window).on('load', function() {
	setTimeout(function() {
		scrollSite.update()
	}, 1000)
})
