$(window).on('load', function() {
	var alertDiv = $('#videocampanha .alert')
	var videocampanha = $('#videocampanha')

	// Mostra a div de alerta quando o mouse entra na div owl-carousel-card
	videocampanha.on('mouseenter', function() {
		alertDiv.show()
	})

	// Esconde a div de alerta quando o mouse sai da div owl-carousel-card
	videocampanha.on('mouseleave', function() {
		alertDiv.hide()
	})

	// Move a div de alerta para acompanhar o mouse dentro da div owl-carousel-card
	videocampanha.on('mousemove', function(event) {
		alertDiv.css({
			left: event.clientX + 10,
			top: event.pageY - window.pageYOffset + 10 // Ajuste para levar em consideração o scroll da janela
		})
	})
})
